.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .carousel-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: relative;

    /********   Product images carousel   *******/
    .carousel {
      border-radius: 10px;
      width: 38vw;
      background-color: white !important;
      img{
        height: 100%;
        width: auto;
        object-fit: contain;
        cursor: pointer;

      }

      @media (max-width: 1199px) {
        width: 40vw;

      }

      @media (max-width: 991px) {
        width: 55vw;

      }

      @media (max-width: 767px) {
        width: 70vw;

      }

      @media (max-width: 600px) {
        width: 90vw;

      }

  

      .all-dots {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        overflow-x: scroll;
        border-radius: 0 0 15px 15px;

        button {
          border: 1px solid #ccc;
          width: 80px;
          padding: 0px !important;
          border-radius: 5px;
          margin: 8px;
          opacity: 0.4;

          &:hover {
            border-color: #e16929;
            outline: none;
            box-shadow: 0 0 3px 2px #e16929;
            border-color: #acc;
            opacity: 0.5;
          }
        }

        .carousel__dot--selected {
          border-color: #e16929;
          outline: none;
          box-shadow: 0 0 3px 2px #e16929;
          border-color: #acc;
          opacity: 1;
        }
      }

      /***** the slider itself ******/
      .carousel__slider {
        margin-left: auto;

        .slider-top-space {
          height: 20px;
        }

        .carousel__image {
          background-size: contain !important;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .carousel__back-button {
          height: 100%;
          width: 50px;
          line-height: 50px;
          background-color: unset;
          border: none;
          position: absolute;
          top: 0%;
          left: 0%;
        }

        .carousel__next-button {
          height: 100%;
          width: 50px;
          line-height: 50px;
          background-color: unset;
          border: none;
          position: absolute;
          top: 0%;
          right: 0%;
        }
      }
    }

  }

  .modal-row {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:white;
    z-index: 1000;
  }

  .close-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 10%;
    z-index: 10;
    text-align: right;

    .cancel-icon{
      height: 40px;
      font-size: 35px;
      cursor: pointer;
    }
  }
  .fake-back-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    height: 95%;
    text-align: left;
    cursor: pointer;

    .fake-back{
      height: 100%;
      align-items: center;
    }
  }
  .fake-next-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    height: 95%;
    text-align: right;
    cursor: pointer;

    .fake-next{
      height: 100%;
      align-items: center;
    }
  }
  
  .modal-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 95vh;
    
      img{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
  }

  .product-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .product-btn {
      flex-basis: 175px;
      margin: 5px 0;
      height: 40px;
      color: $font-color;
      font-size: 13px;
      font-weight: 700;
      margin-left: 5px;
      text-decoration: none;
      background-color: white;
      padding: 10px 10px;

      .icon-wrapper {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }

  .desc-wrapper {
    width: 80vw;
    background-color: #fff;
    margin-top: 40px;
    padding-top: 25px;
    border-radius: 10px;

    @media (max-width: 600px) {
      width: 90vw;

    }

    .description-option-text {
      font-size: 14px;
      font-weight: 700;
      color: black;
      padding-left:20px;
      display: inline-block;
      letter-spacing: 0.8px;
      width: 133px;
   
    }
    .description-option-semicolon {
      font-size: 16px;
      font-weight: 600;
      color: black;
      display: inline-block;
      margin-right: 6px;
   
    }

    .description-option-btn {
      text-align: center;
      padding-top:10px;
      width: 70px;
      border: 1px solid $font-color;
      background-color: #e2f0da;
      margin: 5px 0;
      height: 40px;
      display: inline-block;
      color: $font-color;
      font-size: 13px;
      font-weight: 700;
      margin-left: 5px;
      text-decoration: none;
      border-radius: 5px;
    
    }

    .shopee-wrapper {
      width: 70px;
      text-align: center;
      border-radius: 50%;
      background-color: #e2f0da;
      margin: 5px 0;
      height: 40px;
      display: inline-block;
      margin-left: 5px;
      padding: 0;

      img {
        height: 40px;
        // margin-right: 5  px;
      }

    }

  }

  .description-content {
    padding: 20px 20px;

    .text-line {
      font-size: 13px;
      font-weight: 500;
      color: #333333;
      display: block;
      line-height: 20px;
      margin: 0;

      // @media (max-width: 776px) {
      //   padding-left: 5px;
      //   padding-right: 5px;
      // }
    }

    .empty-line {
      height: 20px;
    }
  }

  .wholesale-text {
    border: none;
    margin: 15px 3px 0 0;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    color: #333333;
  }

  .whatsapp-wrapper {
    border: none;
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    color: $font-color;
    text-decoration: none;


    img {
      height: 30px;
      margin-left: 5px;
      border-radius: 5px;
    }
  }
}