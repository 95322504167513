.about-us {
  display: flex;
  justify-content: center;
  padding: 15px 0;

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  .about-us-wrapper {
    padding: 50px;
    width: 80vw;
    background-color: white;
    position: relative;

    @media (max-width: 600px) {
      width: 90vw;
      padding: 40px 5px;
    }

    .about-us-top {
      width: 100%;
      display: flex;

      .about-us-title {
        font-size: 45px;
        font-weight: 700;

        @media (max-width: 767px) {
          font-size: 35px;
        }

        @media (max-width: 480px) {
          font-size: 28px;
        }
      }

      .about-us-line {
        position: relative;
        top: 37px;
        margin-left: 30px;
        border-top: 2px solid black;
        width: 200px;

        @media (max-width: 767px) {
          width: 140px;
          top: 25px;
          margin-left: 25px;
        }

        @media (max-width: 480px) {
          width: 120px;
          top: 20px;
          margin-left: 20px;
        }
      }
    }

    .about-us-explain {
      font-size: 13px;
      color: gray;
      margin-top: 5px;
    }

    .about-us-left {
      display: flex;
      position: relative;
      margin-top: 30px;

      .design-box {
        width: 100px;
        height: 330px;
        background-color: rgba(168, 235, 168, 0.669);
        @media (max-width: 480px) {
          height: 300px;
        }
      }

      .about-image {
        position: absolute;
        top: 30px;
        left: 40px;
        height: 270px;
        width: 85%;
        object-fit: cover;
        border-radius: 10px;
        background-color: white;

        @media (max-width: 767px) {
          width: 90%;
        }

        @media (max-width: 480px) {
          left: 25px;
          top: 15px;
        }
      }
    }

    .about-us-right {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 767px) {
        display: block;
      }

      .text {
        font-size: 13px;
      }
    }
  }
}
