.navbar {
  min-height: 60px;
  padding: 0;
  width: 100%;
  margin: 0 0 30px;
  background-color: white;

  .qne-logo {
    height: 60px;
    width: 60px;
  }

  .navlink-text {
    color: $font-color;
    font-weight: 500;

    &:hover {
      color:$hover-color ;
    }

  }

}